import React from 'react'
import { Helmet } from 'react-helmet'

export default ({ pageContext: { title, url, backgroundColor = 'white' }, ...props }) => (
    <Helmet>
        <title>{title}</title>
        <meta http-equiv="refresh" content={`0;URL='${url}'`} />
        <style>{`
          body {
            background-color: ${backgroundColor};
          }
        `}</style>
    </Helmet>
)
